<template>
  <div>
    <!-- User Info: Input Fields -->
    <!-- Media -->
    <div class="border-bottom pb-2">
      <h4>{{ schoolData.school_org_name.value }}</h4>
      <small>{{ schoolData.school_address.value }}, {{ schoolData.school_city.value }}, {{ schoolData.school_country.value }}</small>
    </div>
    <br>

    <div class="d-flex">
      <feather-icon
          icon="SettingsIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Basic Informations') }}
      </h4>
    </div>
    <b-form class="mt-3">

      <b-row>
        <!-- Field: ORG Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('School org name')"
            label-for="school_org_name"
          >
            <b-form-input
              id="school_org_name"
              v-model="schoolData.school_org_name.value"
            />
          </b-form-group>
        </b-col>

        <!-- Field: name -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School name')"
              label-for="school_name"
          >
            <b-form-input
                id="school_name"
                v-model="schoolData.school_name.value"
                type="text"
            />
          </b-form-group>
        </b-col>

        <!-- Field: email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('School Email')"
            label-for="school_email"
          >
            <b-form-input
              id="email"
              v-model="schoolData.school_email.value"
              type="email"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router'
// import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    schoolData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const remove = id => {
      store.dispatch('userStoreModule/deleteUser', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User Has Been deleted',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'apps-users-list-student',
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while deleting user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,
      remove,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
