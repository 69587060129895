<template>
  <div>
    <!-- School Info: Input Fields -->

    <div class="d-flex">
      <feather-icon
          icon="EditIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Terms and conditions Informations') }}
      </h4>
    </div>
    <b-form class="mt-3">

      <b-row>
        <!-- Field: chool terms url -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('School terms url')"
            label-for="school_terms_url"
          >
            <b-form-input
              id="school_terms_url"
              v-model="schoolData.school_terms_url.value"
            />
          </b-form-group>
        </b-col>

        <!-- Field: name -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School privacy policy URL')"
              label-for="school_privacy_policy"
          >
            <b-form-input
                id="school_privacy_policy"
                v-model="schoolData.school_privacy_policy.value"
                type="text"
            />
          </b-form-group>
        </b-col>

        <!-- Field: email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('School  contact page URL')"
            label-for="school_contact_page"
          >
            <b-form-input
              id="school_contact_page"
              v-model="schoolData.school_contact_page.value"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    schoolData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
