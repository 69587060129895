<template>
  <div>
    <!-- School Info: Input Fields -->

    <div class="d-flex">
      <feather-icon
          icon="PrinterIcon"
          size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Documents and design') }}
      </h4>
    </div>
    <b-form class="mt-3">

      <b-row>
        <!-- Field: primary color -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('School primary color')"
            label-for="school_primary_color"
          >
            <b-form-input
              id="school_primary_color"
              v-model="schoolData.school_primary_color.value"
              type="color"
            />
          </b-form-group>
        </b-col>

        <!-- Field: School logo -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School logo 400px / 130px')"
              label-for="school_logo_header"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ImageIcon" @click="simulateClick('school_logo_header')" />
              </b-input-group-prepend>
              <b-form-input
                  id="school_logo_header"
                  v-model="schoolData.school_logo_header.value"
                  type="text"
                  placeholder="Image url"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: background image -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
              :label="$t('School certificate background image')"
              label-for="school_certificate_background"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ImageIcon" @click="simulateClick('school_certificate_background')" />
              </b-input-group-prepend>
              <b-form-input
                  id="school_logo_header"
                  v-model="schoolData.school_certificate_background.value"
                  type="text"
                  placeholder="Image url"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Documents background -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School Documents background')"
              label-for="school_background_documents"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ImageIcon" @click="simulateClick('school_background_documents')" />
              </b-input-group-prepend>
              <b-form-input
                  id="school_logo_header"
                  v-model="schoolData.school_background_documents.value"
                  type="text"
                  placeholder="Image url"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: School logo -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School opening time')"
              label-for="school_opening_time"
          >
            <b-form-input
                id="school_opening_time"
                v-model="schoolData.school_opening_time.value"
                type="text"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <div class="d-flex mt-3 mb-3">
        <feather-icon
            icon="ShoppingCartIcon"
            size="19"
        />
        <h4 class="mb-0 ml-50">
          {{ $t('Ecommerce settings') }}
        </h4>
      </div>

      <b-row>
        <!-- Field: Documents ecommerce // @todo: implement list of payement gatways vuelist-->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School payment gateway')"
              label-for="school_payment_gateway"
          >
            <v-select
                v-model="schoolData.school_payment_gateway.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="paymentOptions"
                :clearable="false"
            />
          </b-form-group>
        </b-col>

        <!-- Field: School currency -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School currency')"
              label-for="school_currency"
          >
            <b-form-input
                id="school_currency"
                v-model="schoolData.school_currency.value"
                type="text"
            />
          </b-form-group>
        </b-col>

        <!-- Field: email -->
        <b-col
            cols="12"
            md="4"
        >
          <b-form-group
              :label="$t('School email (from)')"
              label-for="school_bot_email"
          >
            <b-form-input
                id="school_bot_email"
                v-model="schoolData.school_bot_email.value"
                type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-file
          ref="refInputElimg"
          v-model="imageFile"
          :fsrc="fsrc"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="getUploadContext"
      />
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    schoolData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentOptions: [],
      imageFile: '',
      clickSrc: null,
    }
  },
  mounted() {
    this.getPaymentGateways()
  },
  methods: {
    getPaymentGateways() {
      this.$http.get('/payment-gateways').then(res => { this.paymentOptions = res.data })
    },
    simulateClick(srcElement) {
      // trigger click and save the source prop of the field
      this.clickSrc = srcElement
      this.$refs.refInputElimg.$el.click()
    },
    getUploadContext() {
      // eslint-disable-next-line
      let formData = new FormData()
      const file = this.$refs.refInputElimg.$refs.input.files[0]
      formData.append('image', file)
      formData.append('field', this.clickSrc)
      this.$http.post('/school-settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      }).then(response => {
        console.log(response.data)
        this.schoolData.[this.clickSrc].value = response.data
      })
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.schoolData.image = base64
    })

    return {
      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
 @import '@core/scss/vue/libs/vue-select.scss';
</style>
