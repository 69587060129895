import axios from '@axios'

export default {
  namespaced: true,
  state: {
    settings: {
      school_address: {
        value: null,
      },
      school_org_number: {
        value: null,
      },
      school_currency: {
        value: null,
      },
      school_primary_color: {
        value: null,
      },
      school_certificate_background: {
        value: null,
      },
      school_background_documents: {
        value: null,
      },
      school_logo_header: {
        value: null,
      },
      school_website: {
        value: null,
      },
      school_postal_code: {
        value: null,
      },
      school_email: {
        value: null,
      },
      school_certificate_background_design: {
        value: null,
      },
      school_privacy_policy: {
        value: null,
      },
      school_contact_page: {
        value: null,
      },
      school_city: {
        value: null,
      },
      school_org_name: {
        value: null,
      },
      school_phone: {
        value: null,
      },
      school_name: {
        value: null,
      },
      school_payment_gateway: {
        value: null,
      },
      school_bot_email: {
        value: null,
      },
      school_opening_time: {
        value: null,
      },
      school_country: {
        value: null,
      },
      school_terms_url: {
        value: null,
      },
    },
  },
  /* eslint-disable camelcase */
  getters: {},
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
  },
  actions: {
    fetchSchool(store, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-school/${id}`) // @todo: this has to be got from .env or from first time login user data
          .then(response => {
            // store.commit('setSettings', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // updateSchool(store, { payload }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //         .post(`/get-school/${payload.id}`) // @todo: this has to be got from .env or from first time login user data
    //         .then(response => {
    //           // store.commit('setSettings', settings)
    //           resolve(response)
    //         })
    //         .catch(error => reject(error))
    //   })
    // },
    updateSchool(ctx, schoolData) {
      return new Promise((resolve, reject) => {
        axios
            .put(`/set-school/${schoolData.orgId}`, schoolData)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
