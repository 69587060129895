<template>
  <div>

    <!-- Header: Personal Info -->
<!--    <div class="d-flex">-->
<!--      <feather-icon-->
<!--        icon="UserIcon"-->
<!--        size="19"-->
<!--      />-->
<!--      <h4 class="mb-0 ml-50">-->
<!--        {{ $t('Personal Information') }}-->
<!--      </h4>-->
<!--    </div>-->

    <!-- Form: Personal Info Form -->
    <b-form class="mt-2">
      <b-row>

        <!-- Field: org number -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('Organisation number')"
            label-for="school_org_number"
          >
            <b-form-input
              v-model="schoolData.school_org_number.value"
              id="school_org_number"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('phone')"
            label-for="school_phone"
          >
            <b-form-input
              id="school_phone"
              v-model="schoolData.school_phone.value"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Nationality -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('Website URL')"
            label-for="school_website URL"
          >
            <b-form-input
                v-model="schoolData.school_website.value"
                id="school_website"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          {{ $t('Address') }}
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line"
            label-for="school_address"
          >
            <b-form-input
              id="school_address"
              v-model="schoolData.school_address.value"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="('Postcode - City')"
            label-for="school_postal_code"
          >
            <div class="d-flex justify-content-start">
              <b-form-input
                id="school_postal_code"
                v-model="schoolData.school_postal_code.value"
                style="width: 30%"
                class="mr-25"
              />
              <b-form-input
                id="school_city"
                v-model="schoolData.school_city.value"
                style="width: 70%"
              />
            </div>
          </b-form-group>
        </b-col>

        <!-- Field: Country -->
        <!-- Country -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label-for="school_country"
            label="Country"
          >
            <v-select
              v-model="schoolData.school_country.value"
              :reduce="x => x.name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="countryOptions"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import TeacherTimeFrame from '@/views/admin/teachers-list/TeacherTimeFrame.vue'
// import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
// import store from '@/store'
// import router from '@/router'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, VSelect,
  },
  props: {
    schoolData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      levelOptions: [],
      countryOptions: [],
    }
  },
  mounted() {
    this.getCountries()
  },
  methods: {
    getCountries() {
      this.$http.get('/countries/list?perPage=600').then(res => { this.countryOptions = res.data.rows })
    },
  },
  setup() {
    const dayOptions = [
      { label: 'Mo', value: 'mo' },
      { label: 'Tu', value: 'tu' },
      { label: 'We', value: 'we' },
      { label: 'Th', value: 'th' },
      { label: 'Fr', value: 'fr' },
      { label: 'Sa', value: 'sa' },
      { label: 'Su', value: 'su' },
    ]

    // const toast = useToast()

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]
    const saturdayTime = ref([{
      start: '',
      end: '',
      days: 'sa',
    }])

    const contactOptionsOptions = ['Email', 'Message', 'Phone']
    const selectedLevels = ref([])
    const selectedDays = ref([])

    return {
      selectedDays,
      selectedLevels,
      saturdayTime,
      dayOptions,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
      // onSave,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
