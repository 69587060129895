<template>
  <component :is="schoolData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="schoolData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching school data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No user found with this school id') }}
      </div>
    </b-alert>

    <school-edit-basic
        v-if="schoolData"
        :schoolData="schoolData"
        class="mt-75 pt-75"
    />

    <!-- Tab: Information -->
    <school-edit-tab-information
        v-if="schoolData"
        :schoolData="schoolData"
        class="mt-75 pt-75"
    />

    <!-- Tab: school legal and administratif settings -->
    <school-edit-admin-settings
        v-if="schoolData"
        :schoolData="schoolData"
        class="mt-75 pt-75"
    />
    <!-- Tab: school design settings -->
    <school-edit-admin-design
        v-if="schoolData"
        :schoolData="schoolData"
        class="mt-75 pt-75"
    />
    <b-row class="mt-2">
      <b-col>
        <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSave"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>

  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import schoolStoreModule from '../schoolStoreModule'
import SchoolEditBasic from './schoolEditBasic.vue'
import SchoolEditTabInformation from './SchoolEditTabInformation.vue'
import SchoolEditAdminSettings from './schoolEditAdminSettings.vue'
import schoolEditAdminDesign from './schoolEditAdminDesign.vue'
// import router from '@/router'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BButton,
    SchoolEditAdminSettings,
    schoolEditAdminDesign,
    SchoolEditBasic,
    SchoolEditTabInformation,
  },
  setup() {
    const schoolData = ref(null)
    const toast = useToast()

    const SCHOOL_APP_STORE_MODULE_NAME = 'app-school'

    // Register module
    if (!store.hasModule(SCHOOL_APP_STORE_MODULE_NAME)) store.registerModule(SCHOOL_APP_STORE_MODULE_NAME, schoolStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCHOOL_APP_STORE_MODULE_NAME)) store.unregisterModule(SCHOOL_APP_STORE_MODULE_NAME)
    })

    const onSave = () => {
      // const toast = useToast()
      const payload = { ...schoolData.value }
      store.dispatch('app-school/updateSchool', payload)
          .then(() => {
            console.log('success')
            toast({
              component: ToastificationContent,
              props: {
                title: 'School informations had been updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            // router.back()
            // emit('refetch-data')
            // emit('update:is-add-new-user-sidebar-active', false)
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      })
    }

    onMounted(() => {
      store.dispatch('app-school/fetchSchool', { id: 1 })
          .then(response => {
            const schoolSettings = { ...schoolStoreModule.state.settings }
            const newSchoolSettings = {}

            Object.keys(schoolSettings).forEach(k => {
              if (typeof response.data.[k] !== 'undefined') {
                newSchoolSettings[k] = response.data.[k]
                // console.log(newSchoolSettings[k])
              } else {
                newSchoolSettings[k] = { value: null }
              }
            })
            schoolData.value = newSchoolSettings
          })
          .catch(error => {
            if (error.response.status === 404) {
              schoolData.value = undefined
            }
          })
    })

    return {
      schoolData,
      onSave,
    }
  },
}
</script>

<style>

</style>
